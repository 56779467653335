require('./bootstrap');

try {
    window.$ = window.jQuery = require('jquery');
} catch (e) {}

const video_wrapper = $('.youtube-video-place');
if(video_wrapper.length){
    $('.play-youtube-video').on('click', function() {
        video_wrapper.html(
            '<iframe class="embed-responsive-item" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" src="' + video_wrapper.data('yt-url') + '" allowfullscreen></iframe>'
        );
    });
}

$(document).ready(function(){
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#back-to-top').fadeIn();
        } else {
            $('#back-to-top').fadeOut();
        }
    });
    // scroll body to 0px on click
    $('#back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });
});